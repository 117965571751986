import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UtilsService } from '../../../../core/helpers/utils.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Contentmanagement } from '../../../../core/constants/content-management';
import { Report } from '../../../../core/constants/report';
import { SiteManagement } from '../../../../core/constants/site-management';
import { Assetmanagement } from '../../../../core/constants/asset-management';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalLogBookService {

  utilsService: UtilsService = inject(UtilsService);
  contentManagement = new Contentmanagement();
  report = new Report();
  siteManagement = new SiteManagement();
  assetManagement = new Assetmanagement();

  getComponentTypes(filter:string){
    return this.utilsService.httpGet(this.contentManagement.componentTypes(filter))
  }
  getClassDescriptions(compType:string,filter:string){
    return this.utilsService.httpGet(this.contentManagement.classDescriptions(compType,filter))
  }
  getDesignDocuments(payload: any){
    return this.utilsService.httpPost(this.contentManagement.designDocuments(),payload);
  }
  getReportTypes(){
    return this.utilsService.httpGet(this.report.reportTypes());
  }
  getSitesByCustomer(customerId: number){
    return this.utilsService.httpGet(this.siteManagement.sitesByCustomerId(customerId));
  }
  getAssetsBySite(siteId: number){
    return this.utilsService.httpGet(this.assetManagement.assetsBySite(siteId));
  }
  getOccurrenceTypes(){
    return this.utilsService.httpGet(this.report.occurrenceType());
  }
  getAutomatedReports(pageNumber: number, pageSize: number, customerId: number, searchString: string){
    return this.utilsService.httpGet(this.report.automatedReports(pageNumber, pageSize, customerId, searchString));
  }
  getReportsList(pageNumber: number, pageSize: number, searchString: string, payload: any){
    return this.utilsService.httpPost(this.report.reportsList(pageNumber, pageSize, searchString), payload);
  }
  getAssets(customerId: number){
  return this.utilsService.httpGet(this.siteManagement.sitesByCustomerId(customerId)).pipe(
    map((sites: any[]) => sites.map(site => site.id)),
    switchMap((siteIds: number[]) => {
      let params = new HttpParams();
      siteIds.forEach((id: number) => {
        params = params.append('siteIds', JSON.stringify(id));
      });
      return this.utilsService.httpGetWithHeaders(this.assetManagement.assetsForSiteIds(), params);
    })
  );
  }
  getSubAssets(assetId: number){
    return this.utilsService.httpGet(this.assetManagement.subAssetsForAssetId(assetId));
  }

  getSitesForCustomerId(customerId: number): any {
    return this.utilsService.httpGet(this.siteManagement.sitesByCustomerId(customerId))
}


baseUrl = environment.apiURL;


constructor(private http: HttpClient) { }

downloadMultipleDocuments(docUrl: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/multiple/download`;
  return this.http.post<any>(url, docUrl, { responseType: 'blob' as 'json' });

}

getUserInformation(): Observable<any> {
  // console.log('env');
  // console.log(environment);
  const userAccounturl = `${this.baseUrl}/accesscontrol/Account`
  return this.http.get<any>(userAccounturl).pipe(
      map(response => {
  
          let bodyTag = document.documentElement.getElementsByTagName('body');
          
          return response.response;
      })
  )
}

getAccountTypeData(): Observable<any> {
  return this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`);
}

// getSitesForCustomerId(customerId: number): any {
//   return this.http.get<any>(`${this.baseUrl}/sitemanagement/Site/${customerId}`).pipe(catchError(error => of(error)));
// }

getAssetList(siteId: number): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/assetmanagement/Asset/assetBySiteId/${siteId}`);
}

getFeederType(id: number): Observable<any> {
  const url = `${this.baseUrl}/assetmanagement/SubAsset/assetByFeeder/${id}`;
  return this.http.get(url);
}

getReportType(): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/report/Report/reportTypes`).pipe(catchError(error => of(error)));
}

getOccurrenceType(): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/report/Report/occurenceTypes`).pipe(catchError(error => of(error)));
}

postGenerateReports(payload: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/generateReport`;
  return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));;
}

getReportJSON(reportId: any): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/report/Report/report/${reportId}`).pipe(catchError(error => of(error)));

}

deleteReports(reportId: any): Observable<any> {
  const url = `${this.baseUrl}/report/AutomatedReport/automatedReport/${reportId}`;
  return this.http.delete<any>(url);
}

postReportsDocument(pageNumber : number, pageSize : number, searchKeys:string,  payload:any): Observable<any>{
  const url = `${this.baseUrl}/report/DocumentReport/reports/${pageNumber}/${pageSize}?searchString=${searchKeys}`;
  return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
} 
notDownloadedCount = new BehaviorSubject(0);

downloadReportFile(requestId: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/report/download/${requestId}`;
  return this.http.post<any>(url, {});
}

deleteReportsDocument(payload: any): Observable<any> {
  const url = `${this.baseUrl}/report/DocumentReport/generatedReport`;
  return this.http.delete<any>(url,{body: payload}).pipe(catchError(error => of(error)));
}

getAssetsForSiteIds(ids: number[]): Observable<any> {
  let params = new HttpParams();
  ids.forEach((id: number) => {
      params = params.appendAll({ 'siteIds': JSON.stringify(id) });
  })
  const url = `${this.baseUrl}/assetmanagement/Asset/assetsForSiteIds`;
  return this.http.get(url, { params: params });
}

qrCodepdfGenaration(input: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/print`;
  return this.http.post<any>(url, input, {});
}

getQrThumbprint(input: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/QrCode/thumbprint`;
  return this.http.post<any>(url, input);
}

getQrDownloads(pageNumber: number, pageSize: number, searchText = null): Observable<any> {
  let url = `${this.baseUrl}/report/Report/qrCodeRequests/${pageNumber}/${pageSize}`;
  if (searchText) {
      const searchString = encodeURIComponent(searchText);
      url += `?searchString=${searchString}`;
  }
  return this.http.get(url);
}

deleteQrDownloads(ids: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/delete/qrCodeRequest`;
  return this.http.delete<any>(url, { body: ids });
}

downloadFile(requestId: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/qrCodeDownlodad/${requestId}`;
  return this.http.get<any>(url, { responseType: 'blob' as 'json' });
}

getTagTemplate(customerId: number): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/generate/DocumentTagging/${customerId}`;
  return this.http.get<any>(url, { responseType: 'blob' as 'json' });
}

BulkUploadTags(file: any): Observable<any> {
  const formData = new FormData();
  formData.append('file', file)
  const url = `${this.baseUrl}/contentmanagement/Logbook/upload/DocumentTagging`;
  // console.log(formData.getAll('file'));
  return this.http.post<any>(url, formData);
}

deleteDesignDoc(docId: number, isOldRecord: boolean): Observable<any> {
  let url = `${this.baseUrl}/contentmanagement/Logbook/deleteDocument/${docId}/${isOldRecord}`;
  return this.http.delete<any>(url);
}

downloadDocuments(docUrl: string, repoId: string): Observable<any> {
  let url = `${this.baseUrl}/contentmanagement/Logbook/single/download/?link=${docUrl}`;
  if (repoId) url += `&repoId=${repoId}`;
  return this.http.get<any>(url, { responseType: 'blob' as 'json' });

}

getComponentClasses(componentType: string, filter?: string): Observable<any> {
  let url = `${this.baseUrl}/contentmanagement/Document/componentClasses?type=${componentType}`;
  if (filter) {
      url += `&filter=${filter}`
  }
  return this.http.get<any>(url).pipe(catchError(error => of(error)));
}

getDesignDocSheet(payload: any) {
  const url = `${this.baseUrl}/contentmanagement/Document/generate/DesingDocSheet`;
  return this.http.post<any>(url, payload, { responseType: 'blob' as 'json' });
}

uploadDocumentMetadata(data: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/metadata`;
  return this.http.post<any>(url, data);
}

uploadDocuments(files: any): Observable<any> {
  // console.log(files);
  // console.log(files[0]);
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i])
  }
  //formData.append('customerId', customerId);
  //formData.append('file',files);
  const url = `${this.baseUrl}/contentmanagement/Logbook`;
  // console.log(formData.getAll('file'));
  return this.http.post<any>(url, formData);
}


validateLoogbook(data: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/Validate`;
  return this.http.post<any>(url, data).pipe(catchError(error => of(error)));;
}


getDocType(): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/docTypes`;
  return this.http.get(url);
}

downloadBulkQRCode(): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/BulkQrCode/generate`;
  return this.http.get<any>(url, { responseType: 'blob' as 'json' });
}

uploadBulkQRCode(formData: any): Observable<any> {
  const url = `${this.baseUrl}/report/Report/bulkUploadQRCode`;
  return this.http.post<any>(url, formData, { responseType: 'blob' as 'json' }).pipe(catchError(error => of(error)));
}

 //get Asset Categories
 getAssetCategories(): Observable<any> {
  const url = `${this.baseUrl}/assetmanagement/Asset/assetCategoriesByCust`;
  return this.http.get(url);
}
GetAssetByCategory(categoryId: any): Observable<any> {
  const url = `${this.baseUrl}/assetmanagement/Asset/AssetsByCategory/${categoryId}`;
  return this.http.get<any>(url);
}

getDocumentCategories(): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/categories`;
  return this.http.get(url);
}

UpdateDocVersion(type: string, files: any, input: any): Observable<any> {
  const formData = new FormData();
  formData.append('file', files[0]);
  const url = `${this.baseUrl}/contentmanagement/Logbook/version/${type}?url=${input}`;
  return this.http.put(url, formData, input);
}

//get Documents Categories list
UpdateVersionMeta(input: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/version/metadata`;
  return this.http.put(url, input);
}

getSites(): any {
  return this.http.get<any>(`${this.baseUrl}/sitemanagement/Site`);
}

getGeneralSettings(userId?: number) {
  //const moduleMaster = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/moduleMaster`).pipe(catchError(error => of(error)));
  const notificationMaster = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/notificationMaster`).pipe(catchError(error => of(error)));
  const timeZones = this.http.get(`${this.baseUrl}/maintenanceworkflow/AdminSettings/timezones`).pipe(catchError(error => of(error)));
  const adminType = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`).pipe(catchError(error => of(error)));
  const pushNotification = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/pushNotification/${userId}`).pipe(catchError(error => of(error)));

  return forkJoin({
    //moduleMaster: moduleMaster,
    notificationMaster: notificationMaster,
    timeZones: timeZones,
    adminType: adminType,
    pushNotification
  });
}

trashDocData(data: any, istrash: boolean, version?: string): Observable<any> {
  let options = {
      body: data,
  };
  let url = `${this.baseUrl}/contentmanagement/Logbook/trash/${istrash}`;
  if (version) {
      url += `?version=${version}`
  }
  return this.http.delete<any>(url, options);
}

deleteDocuments(docIds: any): Observable<any> {
  const url = `${this.baseUrl}/contentmanagement/Logbook/delete`;
  return this.http.delete<any>(url, { body: docIds });
}


getAllDocuments$: BehaviorSubject<boolean> = new BehaviorSubject(true);

getFavAndSharDocuments$: BehaviorSubject<boolean> = new BehaviorSubject(true);


}
